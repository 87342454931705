import { extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import { i18n } from '@/main';

const isEmailValid = (input: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input);
const isPhoneNumberValid = (input: string) => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(input);

extend('field', {
  ...required,
  message: (_, {_field_, _value_}) => {
    return _field_ !== '{field}'
      ? i18n.t('validation.field', {_field_}) as string
      : i18n.tc('validation.required');
  }
});

extend('required', {
  ...required,
  message: () => i18n.tc('validation.required')
});

extend('objectRequired', {
  validate: (value, args) => {
    if (value) {
      if (args.length) {
        if (Object.keys(value).includes(args[0])) {
          return !!value[args[0]];
        }
        if (process.env.NODE_ENV !== 'production') {
          console.warn('The key is not found in value');
        }
      } else {
        return !(Object.keys(value).filter((item) => !value[item]).length);
      }
    }

    return false;
  },
  message: () => i18n.tc('validation.required')
});

extend('email', {
  ...email,
  message: () => i18n.tc('validation.invalid')
});

extend('login', {
  validate: (value) => isEmailValid(value) || isPhoneNumberValid(value),
  message: () => i18n.tc('validation.invalid')
});

extend('boolean', {
  validate: (value) => !!value,
  message: () => i18n.tc('validation.invalid')
});

extend('number', {
  validate: (value) => /^[\d()\-+]+$/.test(value),
  message: () => i18n.tc('validation.invalid')
});

extend('password', {
  validate: (value) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\\[\]{}|'])[\w!@#$%^&*()_+\-=\\[\]{}|']{8,128}$/.test(value),
  message: () => i18n.tc('validation.securityRequirements')
});

extend('confirmedPassword', {
  ...confirmed,
  message: () => i18n.tc('validation.passNoMatch')
});

extend('phone', {
  validate: (value) => !!(value?.number && (value.number.length > 4)),
  message: () => i18n.tc('validation.invalid')
});

extend('phoneNotRequired', {
  validate: (value) => {
    return value?.number
      ? !(value.number.length >= 1 && value.number.length <= 4)
      : true;
  },
  message: () => i18n.tc('validation.invalid')
});

extend('addressRequired', {
  validate: (value) => {
    return !!(value?.placeId && value?.title);
  },
  message: () => i18n.tc('validation.invalid')
});
